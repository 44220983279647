import {
    Menu, Avatar, Divider,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoLogOutOutline } from 'react-icons/io5';
import cs from 'classnames';
import styles from './styles.module.scss';

import IMAGES from '../../const/images';
import { startLogout } from '../../actions/auth';

interface SideMenuProps {
  isCollapsed: boolean;
}

const SideMenu = ({ isCollapsed }: SideMenuProps) => {
    const { t: tp } = useTranslation('shared', { keyPrefix: 'PAGES' });

    const dispatch = useDispatch<any>();

    const pages = [
        {
            index: 0,
            title: tp('HOME'),
            icon: <Avatar shape="square" size="small" src={IMAGES.icons.home} />,
            href: '/',
        },
        {
            index: 1,
            title: tp('BOOKINGS'),
            icon: <Avatar shape="square" size="small" src={IMAGES.icons.package} />,
            href: '/bookings',
        },
        {
            index: 2,
            title: tp('DRIVERS'),
            icon: <Avatar shape="square" size="small" src={IMAGES.icons.driver} />,
            href: '/drivers',
        },
        {
            index: 3,
            title: tp('SETTINGS'),
            icon: <Avatar shape="square" size="small" src={IMAGES.icons.settings} />,
            href: '/settings',
        },
    ];

    return (
        <div className={styles.sideMenu}>
            <Menu
                mode="inline"
            >
                <div className={cs(styles.infoDelivery, { [styles.collapsed]: isCollapsed })}>
                    <Avatar
                        src={IMAGES.logo.provisional}
                        size={isCollapsed ? 48 : 84}
                        className={styles.logo}
                        shape="square"
                    />
                </div>

                { pages.map((page) => (
                    <Menu.Item
                        className={cs(styles.item, { [styles.collapsed]: isCollapsed })}
                        key={page.index}
                        icon={page.icon}
                        title={page.title}
                    >
                        <Link to={page.href}>
                            <span className="text paragraph--3 medium">
                                {page.title}
                            </span>
                        </Link>
                    </Menu.Item>
                )) }

                <Divider className={styles.divider} />

                <Menu.Item
                    className={cs(styles.item, { [styles.collapsed]: isCollapsed })}
                    key="logout"
                    icon={<IoLogOutOutline size={20} color="#6C8EEF" />}
                    title={tp('LOGOUT')}
                    onClick={() => dispatch(startLogout())}
                >
                    <span className="text paragraph--3 medium">
                        { tp('LOGOUT') }
                    </span>
                </Menu.Item>
            </Menu>
        </div>
    );
};

export default SideMenu;
