import {
    Space, Tabs, notification,
} from 'antd';
import { get } from 'lodash';

import { BiPackage } from 'react-icons/bi';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import HeaderSection from '../components/HeaderSection';
import TableBookings from '../components/TableBookings';

import { getBookingsByDelivery } from '../api/workspace';

const { TabPane } = Tabs;

const Bookings = () => {
    const { t: tb } = useTranslation('bookings', { keyPrefix: 'BOOKINGS' });

    const [tabActive, setTabActive] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [bookings, setBookings] = useState([]);

    const [currentPage, setCurrentPage] = useState<any>(1);
    const [pageSize, setPageSize] = useState(6);
    const [pages, setPages] = useState(0);
    const [urlFetch, setUrlFetch] = useState<any>();

    const title : string = tabActive === 1 ? tb('ACTIVE_BOOKINGS') : tb('PAST_BOOKINGS');
    const { active: activeWorkspace } = useSelector((state: any) => state.workspace);

    const bookingTabs = [
        {
            key: 1,
            name: tb('ACTIVE_BOOKINGS'),
            total: 0,
        },
        {
            key: 2,
            name: tb('PAST_BOOKINGS'),
            total: 0,
        },
    ];

    const getPastBookings = () => getBookingsByDelivery({
        smallFleetID: activeWorkspace._id,
        ...urlFetch,
    });

    const getActiveBookings = async () => getBookingsByDelivery({
        smallFleetID: activeWorkspace._id,
        ...urlFetch,
    });

    const getBookings = async () => {
        setIsLoading(true);
        const request = tabActive === 1 ? getActiveBookings : getPastBookings;
        try {
            const { body } : any = await request();
            const { allBookings, currentPage, pages } = body;
            setBookings(allBookings);
            setCurrentPage(currentPage);
            setPages(pages);
            setPageSize((pageSize: number) => pageSize);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (!activeWorkspace) return;
        getBookings();
    }, [urlFetch, activeWorkspace]);

    useEffect(() => {
        let query: any = {
            page: currentPage,
            limit: pageSize,
        };
        if (tabActive === 1) {
            query = {
                ...query,
                onlyActives: true,
            };
        } else if (tabActive === 2) {
            query = {
                ...query,
                onlyPast: true,
            };
        }
        setUrlFetch(query);
    }, [tabActive, currentPage, pageSize]);

    const onPageChange = (page: number) => setCurrentPage(page);
    const onPageSizeChange = (size: number) => setPageSize(size);

    const refreshData = async () => {
        try {
            await getBookings();
            notification.success({
                message: tb('BOOKINGS_UPDATED'),
            });
        } catch (error) {
            const description = get(error, 'response.data.message');
            notification.error({
                message: 'Error',
                description,
            });
        }
    };

    return (
        <div className="contentLayouts fullWidth fullHeight bg--primary-white">
            <Space direction="vertical" size={64} className="fullWidth">
                <Space direction="vertical" size={32} className="fullWidth">

                    <HeaderSection
                        title={tb('ALL_BOOKINGS')}
                        icon={<BiPackage size={40} className="iconHeader" />}
                    />

                    <Tabs
                        onChange={(tab: any) => setTabActive(Number(tab))}
                        defaultActiveKey={tabActive.toString()}
                        size="small"
                        type="card"
                    >
                        {
                            bookingTabs.map((tab: any) => (
                                <TabPane tab={tab.name} key={tab.key} />
                            ))
                        }
                    </Tabs>

                    <span className="title--header-2 bold cl--secondary-purple">
                        { title }
                    </span>

                    <TableBookings
                        data={bookings}
                        isLoading={isLoading}
                        currentPage={currentPage}
                        pages={pages}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                        pageSize={pageSize}
                        refreshData={refreshData}
                    />
                </Space>
            </Space>
        </div>
    );
};

export default Bookings;
