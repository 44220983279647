import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getBookingsByDelivery } from '../api/workspace';

const useBookings = ({ userID, args = {} } : any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pages, setPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<any>(args.limit || 10);

    const [data, setData] = useState([]);
    const { active: activeWorkspace } = useSelector((state: any) => state.workspace);

    const getBookings = async (props: any) => {
        setLoading(true);
        const query = props;
        if (userID && userID !== 'all') query.userID = userID;
        const { onlyActives = true } = args;
        getBookingsByDelivery({
            onlyActives,
            smallFleetID: activeWorkspace._id,
            ...query,
        }).then(({ body } : any) => {
            const { allBookings, currentPage, pages } = body;
            setData(allBookings);
            setCurrentPage(currentPage);
            setPages(pages);
            setPageSize((pageSize: number) => props.limit || pageSize);
        }).catch((error: any) => {
            console.log(error);
        }).finally(() => setLoading(false));
    };

    useEffect(() => {
        if (!activeWorkspace) return;
        getBookings({ limit: 10 });
    }, [userID, activeWorkspace]);

    const refreshData = () => {
        getBookings({ page: currentPage, limit: pageSize });
    };

    const onPageChange = (page: number) => {
        getBookings({ page, limit: pageSize });
    };

    const onPageSizeChange = (size: number) => {
        getBookings({ page: 1, limit: size });
    };

    return [
        loading,
        data,
        {
            currentPage,
            pages,
            pageSize,
            refreshData,
            onPageChange,
            onPageSizeChange,
        },
    ];
};

export default useBookings;
