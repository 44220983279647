import axios from 'axios';
import { BASE_DASHBOARD_URL, BASE_URL } from './index';

export const getWorkspaces = () => axios.get(
    `${BASE_DASHBOARD_URL}/delivery-partner/getSmallFleetByUser`,
);

export const getBookingsByDelivery = (queryParams: any) => {
    const urlParams = new URLSearchParams(queryParams);
    return axios(`${BASE_DASHBOARD_URL}/delivery-partner/getBookings?${urlParams.toString()}`);
};

export const createWorkspace = (
    data: any,
) => axios.post(`${BASE_DASHBOARD_URL}/delivery-partner/createSmallFleet`, data);

export const uploadWorkspaceLogo = (
    file: any,
) => {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', 'SMALL_FLEETS');

    return axios.post(
        `${BASE_URL}/user/uploadFile`,
        formData,
        {
            headers: { 'Content-Type': 'multipart/form-data' },
        },
    );
};
export const editWorkspace = (
    data: any,
) => axios.put(`${BASE_DASHBOARD_URL}/delivery-partner/editSmallFleet`, data);
