import { useState } from 'react';
import useBookings from '../../hooks/useBookings';
import TableBookings from '../TableBookings';
import { TableBookingsProps } from '../TableBookings/TableBookings';

const Bookings = () => {
    const [userID] = useState<any>('all');
    const [
        loadingBookings,
        bookings,
        tableProps,
    ] = useBookings(userID);

    return (
        <div>
            <TableBookings
                data={bookings}
                isLoading={loadingBookings as boolean}
                {...tableProps as Partial<TableBookingsProps>}
            />
        </div>
    );
};

export default Bookings;
