/* eslint-disable react/require-default-props */
import {
    Table, Avatar, Space, List, Button, Tooltip,
} from 'antd';
import { BsPhone } from 'react-icons/bs';
import { IoReloadOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import cs from 'classnames';

import IMAGES from '../../const/images';
import { FINISHED, STATUSES } from '../../const/bookingStatus';
import { Booking, BookingStatus } from '../../types/booking';
import { diffTimeInMinutes } from '../../utils/bookings';

const ACTIVE: string[] = [
    STATUSES.READY_FOR_PICKUP, STATUSES.ACCEPTED,
    STATUSES.ARRIVED_AT_PICKUP, STATUSES.WAY_TO_DELIVER,
    STATUSES.ARRIVED_AT_DELIVERY,
];

export interface TableBookingsProps {
  data: any;
  pages?: number;
  currentPage?: number;
  isLoading: boolean;
  onPageChange?: any;
  onPageSizeChange?: any;
  pageSize?: number;
  refreshData?: any;
}

const TableBookings = ({
    data = [],
    pages = 0,
    currentPage = 1,
    isLoading,
    onPageChange,
    onPageSizeChange,
    pageSize = 0,
    refreshData,
}: TableBookingsProps) => {
    const { t: tb } = useTranslation('bookings', { keyPrefix: 'TABLE' });
    const { t: ts } = useTranslation('bookings', { keyPrefix: 'STATUS' });
    const { t: to } = useTranslation('bookings', { keyPrefix: 'ORDER_FINISHED' });

    const navigate = useNavigate();

    const getMinutes = (booking: Booking) => {
        let end;
        if (
            (booking.statusText === BookingStatus.COMPLETED && !booking.bookingCompletionDateTime)
      || FINISHED.includes(booking.statusText)
        ) {
            end = booking.updatedAt;
        } else {
            end = booking.bookingCompletionDateTime;
        }
        return diffTimeInMinutes(booking.createdAt, end);
    };

    const columns = [
        {
            title: tb('ORDER'),
            dataIndex: 'bookingNumericId',
            key: 'bookingNumericId',
            render: (bookingNumericId: number, booking: Booking) => {
                const bookingType = booking.paymentMethod === 'CASH' ? 'collect_and_delivery' : 'send';
                const date = moment(booking.createdAt).format('DD/MM/YY');
                const time = moment(booking.createdAt).format('HH:mm');
                return (
                    <List.Item.Meta
                        className="order"
                        avatar={(
                            <Avatar
                                size={56}
                                className="booking-type"
                                src={IMAGES.booking[bookingType]}
                                shape="square"
                            />
                        )}
                        title={(
                            <span className="paragraph--3 medium cl--secondary-purple number ">
                                {`#${bookingNumericId}`}
                            </span>
                        )}
                        description={(
                            <span className="paragraph--3 medium date">
                                {`${date} | ${time}`}
                            </span>
                        )}
                    />
                );
            },
        },
        {
            title: tb('TIME'),
            dataIndex: '',
            render: (createdAt: any, record: any) => {
                const minutes: number = getMinutes(record);
                return (
                    <div className={cs('table-time flex-row center-row-center', {
                        neutral: minutes > 30,
                        warning: minutes >= 45,
                        dangerous: minutes > 60,
                    })}
                    >
                        <span className="paragraph--3 cl--primary-white medium center">
                            { `${minutes} min` }
                        </span>
                    </div>
                );
            },
        },
        {
            title: to('DISTANCE_COVERED'),
            dataIndex: '',
            render: (booking: Booking) => {
                const { distanceBreakUp: { billedDistance } } = booking;
                return (
                    <div className="table-billedDistance flex-row center-row-center">
                        <Tooltip title={(
                            <Space direction="vertical" size={4}>
                                <span className="paragraph--3 light cl--primary-white left">
                                    { `${to('FROM_POINT')}: ${to('DRIVER_PICKUP_FROM_STORE')}` }
                                </span>
                                <span className="paragraph--3 light cl--primary-white left">
                                    { `${to('TO_POINT')}: ${to('DRIVER_DELIVER_TO_CLIENT')}` }
                                </span>
                            </Space>
                        )}
                        >
                            <List.Item.Meta
                                avatar={(
                                    <Avatar
                                        size={32}
                                        src={IMAGES.images.delivery}
                                        shape="square"
                                    />
                                )}
                                title={(
                                    <Space direction="vertical" size={8}>
                                        <span className="bold left cl--secondary-purple paragraph--3 fullWidth">
                                            { `${to('FROM_POINT')} - ${to('TO_POINT')}` }
                                        </span>
                                    </Space>
                                )}
                                description={(
                                    <span className="paragraph--3 medium left">
                                        { `${billedDistance?.toFixed(2)} Km` }
                                    </span>
                                )}
                            />
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: tb('DRIVER'),
            dataIndex: 'driverName',
            key: 'driverName',
            // eslint-disable-next-line react/display-name
            render: (driverName: string, booking: Booking) => (
                <div className="driver">
                    {!driverName
                        ? <span className="unassigned center">{tb('NOT_ASSIGNED')}</span>
                        : (
                            <div className="flex-column center-column-start">
                                <span className="paragraph--3 medium">{driverName}</span>
                                <Space
                                    direction="horizontal"
                                    size={8}
                                    className="driver-mobile flex-row center-row-center"
                                >
                                    <BsPhone color="#6C8EEF" style={{ verticalAlign: 'middle' }} size={16} />
                                    <span className="paragraph--3 medium">
                                        {booking.driverMobile || tb('NOT_AVAILABLE') }
                                    </span>
                                </Space>
                            </div>
                        )}
                </div>
            ),
        },
        {
            title: tb('STORE'),
            dataIndex: 'businessName',
            key: 'businessName',
            width: 160,
            render: (businessName: string, booking?: Booking) => (
                <div className="business">
                    { booking?.regionID?.regionStatus
                        ? (
                            <List.Item.Meta
                                /* TODO Add Traffic Light  */
                                title={<span className="paragraph--3 medium center">{businessName}</span>}
                            />
                        )
                        : (
                            <span className="paragraph--3 medium center">{businessName}</span>
                        )}
                </div>
            ),
        },
        {
            title: tb('STATE'),
            dataIndex: 'statusText',
            key: 'statusText',
            width: 210,
            render: (statusText: BookingStatus) => (
                <div className={cs('table-status', statusText)}>
                    {ts(statusText)}
                </div>
            ),
        },

    ];

    return (
        <Space direction="vertical" size={16} className="fullWidth">
            <Button
                style={{ float: 'right' }}
                className="flex-row center-row-center"
                type="primary"
                size="large"
                loading={isLoading}
                icon={<IoReloadOutline size={20} className="cl--primary-white" />}
                onClick={() => refreshData()}
            />
            <Table
                className="mp-table-card"
                dataSource={data}
                columns={columns}
                scroll={{ x: 1000 }}
                locale={{
                    filterConfirm: 'Ok',
                    filterReset: 'Reset',
                    emptyText: tb('NO_DATA'),
                }}
                rowClassName={(row) => (ACTIVE.includes(row.statusText) ? 'tr-new' : '')}
                pagination={{
                    total: pageSize * pages,
                    current: currentPage,
                    pageSize,
                    onChange: onPageChange,
                    onShowSizeChange: onPageSizeChange,
                    showSizeChanger: true,
                }}
                rowKey="_id"
                loading={isLoading}
                onRow={(booking: Booking) => ({
                    onClick: () => {
                        navigate(`/bookings/${booking._id}`);
                    },
                })}
            />
        </Space>
    );
};

export default TableBookings;
